import { UserRepository } from '@/repository/user.repository';
import WebApp from '@twa-dev/sdk';
import { setAccessToken } from '@/utilities';
import { useDispatch } from 'react-redux';
import { updateAccount } from '@/redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
export const useLoginWithTelegram = () => {
  const dispatch = useDispatch();
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const handleLogin = async (point?: string, referralCode?: string) => {
    setIsLoadingLogin(true);
    const tonOauthResponse = await UserRepository.loginWithTelegram(
      WebApp.initData ||
        'query_id=AAG07mw4AwAAALTubDgVmiVr&user=%7B%22id%22%3A7389114036%2C%22first_name%22%3A%22CPSHCM_379DBP_TVKHO_KIET%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22KIET_KHO_379DBP%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1729667473&hash=82a2275f23489e792a86ca2cd2d9c5444ca006ca4d91b611ab59437372d740cf',
      point,
      referralCode
    );
    if (tonOauthResponse?.token) {
      setAccessToken(tonOauthResponse?.token);
      dispatch(
        updateAccount({
          token: tonOauthResponse?.token,
          expiresIn: ''
        })
      );
    } else {
      toast.error('Something went wrong!');
    }
    setIsLoadingLogin(false);
  };

  return {
    handleLogin,
    isLoadingLogin
  };
};
